import shortid from 'shortid';
import React from 'react';
import styled from 'styled-components';
import { formatMargin, formatPrice } from 'shared/data';
import { SubdirectoryArrowRight } from '@styled-icons/material';
import { IInventoryPart } from 'shared/types/dbRecords';
import { CellCopy, ParentCellCopy } from 'shared/styledComponents/typographicElements';
import { FlexRow } from 'shared/containers/FlexContainer/styledComponents';
import { InactiveIndicator, SkuWrapper } from 'shared/styledComponents/utility';
import theme from 'shared/theme';
import { Tooltip } from 'antd';
import ActionButtons from './Buttons/ActionButtons';

const ChildWrapper = styled(FlexRow)`
    justify-content: flex-start;
    margin-left: 8px;
`;
const ChildArrow = styled(SubdirectoryArrowRight)`
    width: 24px;
    margin-bottom: 6px;
    color: ${theme.palette.primary.hue};
`;
export const AverageCostCopy = styled(CellCopy)`
    color: ${theme.palette.primary.hue};
    font-weight: bold;
`;

const SKU_COLUMN = {
  title: 'Sku',
  dataIndex: 'Sku',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: record.Parent ? 'right' : 'center',
      },
    },
    children:
  <>
    {record.Parent ? (
      <ChildWrapper>
        <ChildArrow />
        <SkuWrapper>
          <CellCopy key={shortid.generate()}>{record.Sku}</CellCopy>
          {!record.Active && (
            <InactiveIndicator>INACTIVE</InactiveIndicator>
          )}
        </SkuWrapper>
      </ChildWrapper>
    ) : (
      <>
        {record.Children ? (
          <SkuWrapper>
            <ParentCellCopy key={shortid.generate()}>{record.Sku}</ParentCellCopy>
            {!record.Active && (
              <InactiveIndicator>INACTIVE</InactiveIndicator>
            )}
          </SkuWrapper>
        ) : (
          <SkuWrapper>
            <CellCopy key={shortid.generate()}>{record.Sku}</CellCopy>
            {!record.Active && (
              <InactiveIndicator>INACTIVE</InactiveIndicator>
            )}
          </SkuWrapper>
        )}
      </>
    )}
  </>,
  }),
  width: '14%',
};
const DESCRIPTION_COLUMN = {
  title: 'Description',
  dataIndex: 'Description',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'left',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.Description}</CellCopy>,
  }),
  width: '60%',
};
const VENDOR_COLUMN = {
  title: 'Vendor',
  dataIndex: 'PrefVendorRef.name',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'center',
      },
    },
    children:
  <CellCopy key={shortid.generate()}>{record.Children ? '' : record.PrefVendorRef?.name || ''}</CellCopy>,
  }),
  width: '10%',
};
const SELL_COLUMN = {
  title: 'Sell',
  dataIndex: 'UnitPrice',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <Tooltip title={`${formatMargin(record.PurchaseCost, record.UnitPrice)} margin`}>
    <CellCopy key={shortid.generate()}>{`${formatPrice(record.UnitPrice)}`}</CellCopy>
  </Tooltip>,
  }),
  width: '8%',
};
const COST_COLUMN = {
  title: 'Cost',
  dataIndex: 'PurchaseCost',
  render: (text: string, record: IInventoryPart) => ({
    props: {
      style: {
        textAlign: 'right',
      },
    },
    children:
  <>
    {record.Children?.length ? (
      <Tooltip title={`On hand value: ${formatPrice(record.QtyOnHand * record.PurchaseCost)}`}>
        <AverageCostCopy key={shortid.generate()}>{`${formatPrice(record.PurchaseCost)}`}</AverageCostCopy>
      </Tooltip>
    ) : (
      <Tooltip title={`On hand value: ${formatPrice(record.QtyOnHand * record.PurchaseCost)}`}>
        <CellCopy key={shortid.generate()}>{`${formatPrice(record.PurchaseCost)}`}</CellCopy>
      </Tooltip>
    )}
  </>,
  }),
  width: '8%',
};

const ACTION_COLUMN = {
  title: '',
  dataIndex: 'actionsColumn',
  render: (text: string, record: IInventoryPart) => ({
    children:
  <ActionButtons inventoryPart={record} />,
  }),
  width: '10%',
};
export {
  SKU_COLUMN,
  DESCRIPTION_COLUMN,
  COST_COLUMN,
  SELL_COLUMN,
  VENDOR_COLUMN,
  ACTION_COLUMN,
};
