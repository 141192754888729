import { difference, every, includes } from 'lodash';
import { WOOD_MAP as BODY_WOOD_MAP, WOOD_WEIGHTS } from './bodyParser';
import { INLAY_MAP, WOOD_MAP as NECK_WOOD_MAP } from './neckParser';
import { addMeta, cleanMeta } from '../text';

const _ = require('lodash');

// eslint-disable-next-line import/prefer-default-export
export const customerConfig = (globalConfig: any, customerId: string, partClass: 'GB'|'BB'|'BN'|'GN', forExport: boolean = false) => {
  const custConfig = globalConfig[customerId];
  const partType = _.includes(['BN', 'GN'], partClass) ? 'neck' : 'body';
  if (!custConfig) {
    return {
      ...globalConfig.UNIVERSAL[partType],
      ...globalConfig.UNIVERSAL.global,
    };
  }

  const partConfig = {};

  Object.entries(custConfig[partType]).forEach((e: [string, any]) => {
    const [k, v] = e;
    if (forExport && !v.export) return;
    const universal = _.find(globalConfig.UNIVERSAL[partType], (pt: any) => pt.objectKey === v.objectKey);
    partConfig[k] = {
      ...v,
      label: universal?.label || '',
    };
  });

  Object.entries(globalConfig.UNIVERSAL.global).forEach((e: [string, any]) => {
    const [k, v] = e;
    if (forExport && !v.export) return;
    partConfig[k] = v;
  });

  return {
    ...partConfig,
  };
};

export const UNIVERSAL_REPLACEMENT_TERMS = {
  Black: ['Blk'],
  White: ['Wht'],
  Gold: ['Gld'],
  ' Lite': ['(?<!\\s)Lite'],
  ' XLite': ['(?<!\\s)XLite'],
  ' XXLite': ['(?<!\\s)XXLite'],
};

const BODY_NORMALIZED_TERMS = {
  ...UNIVERSAL_REPLACEMENT_TERMS,
  ...BODY_WOOD_MAP,
  ...WOOD_WEIGHTS,
  WCrv: ['W.?Crv', 'W.?Carve', 'WstCrv'],
  HCrv: ['H.?Crv', 'H.?Carve', 'HeelCrv'],
  ACrv: ['A.?Crv', 'A.?Carve', 'ArmCrv'],
  FC: ['F\\/C'],
  RC: ['[&_]R\\/C_'],
  _: ['\\s_', '_\\s'],
};
const NECK_NORMALIZED_TERMS = {
  ...UNIVERSAL_REPLACEMENT_TERMS,
  ...NECK_WOOD_MAP,
  ...INLAY_MAP,
  Bind: ['Bnd', 'Binding', 'Bind'],
  NoFrets: ['NoFRETS', 'No Frets'],
  CR: ['C\\.R', 'CR(?!.)'],
  Graph: ['GRPH', '1GRPH', '1 GRPH', '1GRAPH', '1 GRAPH'],
  CSGraph: ['CSGrph', 'CSGraph', 'CS Graph', 'CS Grph'],
  DblGraph: ['DBL GRPH', 'DBL Graph', 'DBLGRPH', 'DBLGRAPH'],
  LinedFretless: ['Polystyrene Fret Lines', 'Fret\\s?Lines'],
};

const sanitizeNeckPartDescription = (description: string): string => {
  let newDescription = description;
  // logic
  Object.entries(NECK_NORMALIZED_TERMS).forEach((termSet) => {
    const [term, matches] = termSet;
    const matcher = new RegExp(matches.join('|'), 'ig');
    newDescription = newDescription.replace(matcher, term);
  });

  return newDescription;
};
const sanitizeBodyPartDescription = (description: string): string => {
  let newDescription = description;
  // logic
  Object.entries(BODY_NORMALIZED_TERMS).forEach((termSet) => {
    const [term, matches] = termSet;
    const matcher = new RegExp(matches.join('|'), 'ig');
    newDescription = newDescription.replace(matcher, term);
  });
  return newDescription;
};
export const sanitizePartDescription = (description: string): string => {
  // remove extraneous or bad characters
  const _description = description
    .replace(/&/g, '/')
    .replace(/\s\s/, ' ');
  const sanityFunction = _description.match(/^[G|B]N/) ? sanitizeNeckPartDescription : sanitizeBodyPartDescription;
  // sanitize and add meta chars to description
  return addMeta(sanityFunction(_description));
};

const inlayType = (partDescription: string) => {
  const inlayMatcher = /([a-z\s]+)(Frame[s]?|Crown[s]?|Block|Leaves|Ovals|F)\/([a-z]+)(S)/i;
  const inlayMatch = partDescription.match(inlayMatcher);

  if (!inlayMatch) {
    return [null, null];
  }

  const [match, frontMaterial, frontType, sideMaterial, sideType, ...rest] = inlayMatch;

  return { front: frontType, side: sideType };
};

const GEOMETRIC_KEYS = [
  // body keys
  'armCarve',
  'battery',
  'bentTop',
  'bmh',
  'bound',
  'bridge',
  'bridgePickup',
  'carve',
  'carveTop',
  'comfy',
  'control',
  'crvHeel',
  'fretCount',
  'fretwire.profile',
  'frontInlay.shape',
  'groundWire',
  'jackType',
  'lefty',
  'lefty',
  'middlePickup',
  'model',
  'model',
  'neckMountingHoles',
  'neckPickup',
  'neckPocket',
  'npo',
  'nut',
  'nutType',
  'radius',
  'revPh',
  'roundover',
  'scale',
  'sideInlay.shape',
  'skunk',
  'sth',
  'stringCount',
  'switch',
  'tilt',
  'trAccess',
  'trType',
  'tra',
  'veneer',
  'vintage',
  'waistCarve',
  'weightReductionType',
  'wireRout',
];
const getPartValue = (obj: any, path: string): any => path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);
export const checkGeometricDifferences = (childPart: any, parentPart: any) => GEOMETRIC_KEYS.map((key) => {
  const childValue = getPartValue(childPart, key);
  const parentValue = getPartValue(parentPart, key);
  // Deep equality check
  return JSON.stringify(childValue) === JSON.stringify(parentValue);
});

export const geometricDescriptionParts = (part: any, type: 'neck'|'body') => {
  type GeoKey = {
    key: string;
    type: 'string' | 'match' | 'coerce';
    value: string;
    matcher: RegExp;
  };
  const geometricDescriptionKeys = {
    body: [
      { key: 'type', type: 'string' },
      { key: 'model', type: 'string' },
      { key: 'carveTop', type: 'coerce', value: 'CT' },
      { key: 'lefty', type: 'coerce', value: 'LEFTY' },
      { key: 'scale', type: 'string' },
      { key: 'bridge', type: 'string' },
      { key: 'tra', type: 'coerce', value: 'TRA' },
      { key: 'weightReductionType', type: 'match', matcher: /_wr(hc)?/i },
      { key: 'neckPickup', type: 'string' },
      { key: 'middlePickup', type: 'string' },
      { key: 'bridgePickup', type: 'string' },
      { key: 'armCarve', type: 'match', matcher: /comfy|a(\/)?c(rv)?/i },
      { key: 'battery', type: 'string' },
      { key: 'bmh', type: 'string' },
      { key: 'control', type: 'string' },
      { key: 'crvHeel', type: 'string' },
      { key: 'roundover', type: 'string' },
      { key: 'sth', type: 'string' },
      { key: 'veneer', type: 'coerce', value: 'Veneer' },
      { key: 'waistCarve', type: 'match', matcher: /comfy|w(\/)?c(rv)?/i },

    ],
    neck: [
      { key: 'type', type: 'string' },
      { key: 'scale', type: 'string' },
      { key: 'lefty', type: 'bool' },
      { key: 'model', type: 'string' },
      { key: 'trType', type: 'match', matcher: /[12]W[HPS]/ },
      { key: 'nut', type: 'string' },
      { key: 'carve', type: 'string' },
      { key: 'radius', type: 'string' },
      { key: 'fretwire.profile', type: 'match', matcher: /[0-9]{5}/ },
      { key: 'graphite', type: 'string' },
      { key: 'frontInlay.shape', type: 'string' },
      { key: 'sideInlay.shape', type: 'match', matcher: /[0-9\.]{1,3}mm/ },
      { key: 'bound', type: 'coerce', value: 'Bind' },
      { key: 'tilt', type: 'coerce', value: 'Tilt' },
      { key: 'skunk', type: 'string' },
    ],
  };
  return geometricDescriptionKeys[type].map((key: GeoKey) => {
    const keyValue = getPartValue(part, key.key);
    if (key.type === 'string') {
      return keyValue;
    }
    if (key.type === 'match') {
      const match = part.Description.match(key.matcher);
      return match ? match[0].replace(/_/g, '') : undefined;
    }
    // else if type is 'coerce'
    return !includes(['N', 'None'], keyValue) ? key.value : undefined;
  }).filter((v) => v && !includes(['N', 'None', ''], v))
    .join(', ');
};

export const isSamePart = (aPartData: any, bPartData: any): boolean => {
  const noCheckKeys = [
    'Description',
    'Id',
    'Sku',
    'SyncToken',
    'active',
    'childParts',
    'childSku',
    'createdBy',
    'createdDate',
    'customerId',
    'customer',
    'discount',
    'discountedPrice',
    'id',
    'isChild',
    'lastModifiedBy',
    'lastModifiedDate',
    'lastSold',
    'notes',
    'oldDescription',
    'parent',
    'partCategory',
    'pricing',
    'price',
    'qboId',
    'sortKey',
    'topped',
    'uniqueModifiers',
    'volume',
  ];

  if (aPartData.Description || bPartData.Description) {
    if (cleanMeta(aPartData.Description || '') === cleanMeta(bPartData.Description || '')) return true;
  }

  const aKeys = Object.keys(aPartData).filter((k) => !includes(noCheckKeys, k));
  const bKeys = Object.keys(bPartData).filter((k) => !includes(noCheckKeys, k));
  const keysSame = difference(aKeys, bKeys).length === 0;
  if (!keysSame) return false;

  const valuesSame: boolean = every(aKeys.map((k) => {
    const a = aPartData[k];
    const b = bPartData[k];
    if (typeof a === 'object') {
      return isSamePart(a, b);
    }
    return JSON.stringify(a).toLowerCase() === JSON.stringify(b).toLowerCase();
  }));

  return keysSame && valuesSame;
};
