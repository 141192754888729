import React from 'react';
import { stageRedirect } from 'shared/util';
import {
  RouterTDButton,
} from 'pages/ProductionSchedule/styledComponents';
import { IClientRunner } from '../../../Orders/types';

interface IComponentInterface {
  record: IClientRunner;
}

export default ({ record }: IComponentInterface) => {
  const onClick = (e: any) => {
    e.preventDefault();
    const redirectUrl = window.location.href.match(/router/) ? `/router#${record.id}` : `/schedule/week#${record.id}`;
    const orderId = window.location.href.match(/router/) ? record.orderId : record.id;
    stageRedirect(redirectUrl);
    window.location.href = `/orders/create?edit=true&orderId=${orderId}&customer=${record.customer.DisplayName}&salesOrder=${record.salesOrder}`;
  };

  return (
    <RouterTDButton onClick={onClick}>{record.salesOrder}</RouterTDButton>
  );
};
