import {
  Button, Divider, Dropdown,
} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { DownOutlined } from '@ant-design/icons/lib';
import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DropdownOuterWrapper,
  DropdownWrapper,
  ItemPrice,
} from 'shared/pageElements/styledComponents';
import { formatPrice } from 'shared/data';
import { hideUnselectedOptionsAtom, partEditModeAtom, useTestDataAtom } from 'shared/state/pricingState';

const shortid = require('shortid');

interface ComponentInterface {
  MenuNode: any;
  children?: any;
  title: string;
  price: number;
}

export default ({
  MenuNode, title, price, children,
}: ComponentInterface) => {
  const useTestData = useRecoilValue(useTestDataAtom);
  const editMode = useRecoilValue(partEditModeAtom);

  return (
    <>
      {(editMode || price !== 0)
          && (
          <>
            <Divider orientation="left">{title}</Divider>
            <DropdownOuterWrapper>
              <DropdownWrapper>
                {MenuNode}
                <ItemPrice testMode={useTestData} selected={price !== 0}>
                  {formatPrice(price)}
                </ItemPrice>
              </DropdownWrapper>
            </DropdownOuterWrapper>
              {children}
          </>
          )}
    </>
  );
};
