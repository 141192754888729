import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
// @ts-ignore
import QrReader from 'react-qr-reader';
import qs from 'qs';
import { find, includes } from 'lodash';
import firebase from 'firebase';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  COMPLETE_STEPS,
  isScannerStation,
} from 'shared/data';
import { ORDERS_DB_COLLECTION, currentShopOrderAtom, workOrderUpdateLabelAtom } from 'shared/state/orderState';
import { consumeInventoryParts } from 'shared/router/utils';
import { INVENTORY_ITEMS_COLLECTION, inventoryItemsAtom } from 'shared/state/inventoryState';
import {
  scanStation, updateRunners, CONSTRUCTION_JASON, selectAudio, CNC_STATION_NECK, updateRunnerBom, runnerHistory,
} from 'shared/scanner';
import { H4 } from 'shared/typography';
import {
  currentRunnerAtom,
  routerStepsAtom,
  messageAcknolwedgeAtom, initiateScanAtom,
} from 'shared/state/routingState';
import useFirebase from 'vendor/Firebase';
import { AuthContext } from 'vendor/Firebase/AuthProvider';
import {
  ScannerLogoWrapper, ScannerWrapper,
} from './styledComponents';
import ShopScannerScreensaver from './ShopScannerScreensaver';
import AutoScanOverlay, { AutoScanWithMessageOverlay } from './Components/AutoScanOverlay';
import MultiStepOverlay from './Components/MultiStepOverlay';
import { IRouterStep } from './types';
import { IRunner, IShopOrder } from '../Orders/types';
import { MESSAGE_DB_PATH_ATOM, reorderPointNotificationUsersAtom } from '../../shared/state/messageState';

export default () => {
  const { currentUser } = useContext(AuthContext);
  const { firestore, database } = useFirebase();
  const [scanFound, setScanFound] = useState(false);
  const [shopOrder, setShopOrder] = useRecoilState(currentShopOrderAtom);
  const [runner, setRunner] = useRecoilState(currentRunnerAtom);
  const routerSteps = useRecoilValue(routerStepsAtom);
  const inventoryItems = useRecoilValue(inventoryItemsAtom);
  const inventoryDbString = useRecoilValue(INVENTORY_ITEMS_COLLECTION);
  const reorderItemNotificationUsers = useRecoilValue(reorderPointNotificationUsersAtom);
  const messageDbString = useRecoilValue(MESSAGE_DB_PATH_ATOM);
  const orderDbString = useRecoilValue(ORDERS_DB_COLLECTION);
  const [messageScreenVisible, setMessageScreenVisible] = useRecoilState(messageAcknolwedgeAtom);
  const setInitiateScan = useSetRecoilState(initiateScanAtom);
  const setWorkOrderUpdateLabel = useSetRecoilState(workOrderUpdateLabelAtom);

  const [autoScan, setAutoScan] = useState(false);
  const [autoScanStation, setAutoScanStation] = useState('');
  const [subStations, setSubStations] = useState<IRouterStep[]>([]);
  const [subStationLabel, setSubstationLabel] = useState<string|null>(null);
  const [stepNote, setStepNote] = useState<string|null>(null);
  const [sleepMode, setSleepMode] = useState(true);
  const [timeoutInterval, setTimeoutInterval] = useState(null);
  const [boopLoop, setBoopLoop] = useState(null);

  const finishScan = (order: IShopOrder) => {
    new Audio(selectAudio(currentUser.uid)).play();

    setTimeout(() => {
      firestore.collection(orderDbString).doc(order.id).set(order).then(() => {
        setAutoScan(false);
        setScanFound(false);
      });
    }, 1000);
  };

  const autoScanComplete = (_order: IShopOrder, _runner: IRunner, sId: string, stationName: string) => {
    setAutoScanStation(stationName);
    setAutoScan(true);
    setSubStations([]);
    const { updatedRunner, consumedParts } = updateRunnerBom(_runner, sId, routerSteps, _order.type);
    const today = new Date();
    consumeInventoryParts(
      consumedParts,
      inventoryItems,
      inventoryDbString,
      firestore,
      database,
      messageDbString,
      reorderItemNotificationUsers,
      setWorkOrderUpdateLabel,
      today,
      _order,
    )
      .then((() => {
        const r: any = {
          ...updatedRunner,
          history: runnerHistory(updatedRunner, sId),
          stale: false,
          daysAtStep: 0,
          completed: includes(COMPLETE_STEPS, sId),
        };

        if (includes(COMPLETE_STEPS, sId)) {
          const shipDate = new Date();
          shipDate.setHours(0, 0, 0);
          r.shipDate = firebase.firestore.Timestamp.fromDate(shipDate);
        }
        const [, order] = updateRunners(_order, r, sId);
        firestore.collection(orderDbString).doc(order.id).set(order).then(() => {
          finishScan(order);
        });
      }));
  };

  const autoFinishOrder = (e: any) => {
    e.preventDefault();
    if (boopLoop) {
      // @ts-ignore
      clearInterval(boopLoop);
    }
    setStepNote(null);
    setMessageScreenVisible(false);
    finishScan(shopOrder);
  };

  const autoScanCallback = (sId: string, stationName: string) => (e: any) => {
    autoScanComplete(shopOrder, runner, sId, stationName);
  };

  const onScan = (data: any) => {
    console.log(data);
    if (!data || scanFound) return;

    if (data.match(/runnerId/i)) {
      setScanFound(true);

      const { shopOrderId, runnerId } = qs.parse(data.split('?')[1]);
      firestore.collection(orderDbString).doc(shopOrderId).get().then((doc) => {
        const docData = doc.data() as IShopOrder;
        setShopOrder(docData);

        const currentRunner = find(docData.runners, (r: IRunner) => r.id === runnerId);
        setRunner(currentRunner);

        const stationMatches = routerSteps.filter((r: IRouterStep) => r.type === docData.type)
          .filter((r: IRouterStep) => r.stationId === currentUser.uid);

        if (stationMatches.length > 1
            && currentUser.uid === CNC_STATION_NECK.stationId
            && docData.type.match(/neck/i)) {
          const stations = stationMatches.map((stationMatch: IRouterStep) => ({
            ...stationMatch,
            subName: stationMatch.subName && stationMatch.subName.match(/construction/i) ? 'No' : 'Yes',
          }));
          setSubstationLabel('Has this order been constructed?');
          setSubStations(stations);
        } else if (stationMatches.length > 1) {
          setAutoScanStation(currentUser.uid);
          setSubStations(stationMatches);
        } else if (stationMatches.length === 1) {
          const station = stationMatches[0];
          autoScanComplete(docData, currentRunner, station.id, `${station.name}${station.subName ? ` - ${station.subName}` : ''}`);
        } else if (currentUser.uid === CONSTRUCTION_JASON.stationId) {
          const station = find(routerSteps, (r: IRouterStep) => r.id === CONSTRUCTION_JASON.stepId);
          autoScanComplete(docData, currentRunner, station.id, station.name);
        } else {
          window.location.href = `${data}&stationId=${currentUser.uid}&scanner=true`;
        }
      });
    }
  };

  const onScanError = (e: any) => {
    Modal.error({
      title: 'Uh-oh. That scan didn\'t work. If this persists, let Keith know.',
    });
  };

  const onNotScanning = (e: any) => {
    e.preventDefault();
    window.location.href = '/scanner/manual-entry?scanner=true';
  };

  const onScreensaverClick = () => {
    setSleepMode(false);
    setInitiateScan(true);
  };

  useEffect(() => {
    const timeoutCounter = () => {
      if (messageScreenVisible) return;

      const timeoutCount = localStorage.getItem('scannerTimeout') || '0';
      if (parseInt(timeoutCount, 10) < 1) {
        localStorage.setItem('scannerTimeout', (parseInt(timeoutCount, 10) + 1).toString());
      } else {
        localStorage.setItem('scannerTimeout', '0');
        setSleepMode(true);
      }
    };

    const isScanner = (currentUser.email === 'keithh@wildwoodmfg.com' || window.location.href.match(/localhost/)) ? true : isScannerStation(currentUser.uid);
    const interval = window.location.href.match(/localhost/) ? 10000 : 15000;
    if (!sleepMode && isScanner) {
      // @ts-ignore
      setTimeoutInterval(setInterval(timeoutCounter, interval));
    } else if (sleepMode && timeoutInterval) {
      // @ts-ignore
      clearInterval(timeoutInterval);
      setTimeoutInterval(null);
    }
  }, [sleepMode, messageScreenVisible]);
  const vh = window.innerHeight * 0.01;

  return (
    <>
      {sleepMode ? (
        <ShopScannerScreensaver callback={onScreensaverClick} />
      ) : (
        <>
          {!autoScan && !subStations.length ? (
            <ScannerWrapper scanFound={scanFound}>
              <QrReader
                delay={200}
                onError={onScanError}
                onScan={onScan}
                facingMode="environment"
                style={{ width: '100vw' }}
              />
              <ScannerLogoWrapper scanFound={scanFound}>
                {/* <FlexColumn> */}
                {/*  <Logo /> */}
                {/* </FlexColumn> */}
                <H4 scanFound={scanFound}>{scanStation(currentUser)}</H4>
                {/* <NotScanningButton onClick={onNotScanning}>Manual lookup</NotScanningButton> */}
              </ScannerLogoWrapper>
            </ScannerWrapper>
          ) : (
            <>
              { !stepNote && autoScan && <AutoScanOverlay station={autoScanStation} />}
              { stepNote && autoScan && <AutoScanWithMessageOverlay callback={autoFinishOrder} station={autoScanStation} note={stepNote} />}
              { !!subStations.length
          && <MultiStepOverlay label={subStationLabel} stations={subStations} callback={autoScanCallback} /> }
            </>
          )}
        </>
      )}
    </>
  );
};
