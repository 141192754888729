import React, { useEffect, useState, useRef } from 'react';
import {
  Page, Text, View, StyleSheet, Image,
} from '@react-pdf/renderer';
import { includes, some } from 'lodash';
import { IOrderItem } from 'shared/types/dbRecords';
import { IShopOrder } from 'pages/Orders/types';
import { fancyTops, noFinishing, toppedOrBound } from '../../../shared/data/order';
import PickListPage from './PickListPage';

const style = StyleSheet.create({
  checklistCompany: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    color: 'black',
    position: 'absolute',
    zIndex: 100,
    top: 48,
    left: '69%',
  },
  checklistPDF: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  runnerIndex: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    position: 'absolute',
    top: 75,
    left: '76%',
    zIndex: 100000,
  },
  neckMillingQAChecklistCompany: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    color: 'black',
    position: 'absolute',
    zIndex: 100,
    top: 54,
    left: 175,
  },
  neckMillingQARunnerIndex: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    position: 'absolute',
    top: 54,
    right: 280,
    zIndex: 100000,
  },
  cncChecklistCustomerText: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    position: 'absolute',
    top: 60,
    right: 308,
    zIndex: 100000,
  },
  cncChecklistOrderNumberText: {
    fontFamily: 'Red Hat Display-600',
    fontSize: 12,
    position: 'absolute',
    top: 60,
    right: 80,
    zIndex: 100000,
  },
  checklistView: {
    width: '100%',
    height: '100%',
  },
});

interface IComponent {
  pickItems: { Sku: string; Description: string; PickQty: number }[];
  shopOrder: IShopOrder;
  runnerIndex: number;
  csParts: boolean;
}
const WorkOrderSupportDocsPage = ({
  pickItems, shopOrder, runnerIndex, csParts,
}: IComponent) => {
  const currentRunner = shopOrder.runners[runnerIndex];
  if (runnerIndex === 0) console.log(currentRunner);
  // const cncOnly = false;
  const cncOnly = noFinishing(currentRunner.parts);
  const fancyTopped = shopOrder.type === 'body' && fancyTops(currentRunner.parts);

  return (
    <>
      {(shopOrder.type === 'neck' || includes(['SADOW'], shopOrder.customer.DisplayName)) && (
      <>
        <PickListPage pickItems={pickItems} shopOrder={shopOrder} runnerIndex={runnerIndex} csParts={csParts} />
      </>
      )}

      {(shopOrder.type === 'body' && !fancyTopped) && (
      <Page size="LETTER">
        <View>
          <Text style={style.checklistCompany}>{shopOrder.customer.shortName}</Text>
          <Text style={style.runnerIndex}>{`${shopOrder.salesOrder}  ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
        </View>
        <Image style={style.checklistPDF} src={`/documents/${shopOrder.type}_milling_cnc_signoff.png`} />
      </Page>
      )}
      {(shopOrder.type === 'body' && fancyTopped) && (
        <Page size="LETTER">
          <View>
            <Text style={style.checklistCompany}>{shopOrder.customer.shortName}</Text>
            <Text style={style.runnerIndex}>{`${shopOrder.salesOrder}  ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
          </View>
          <Image style={style.checklistPDF} src="/documents/fancy_top_milling_cnc_checklist.png" />
        </Page>
      )}

      {(shopOrder.type === 'body' && (cncOnly || shopOrder.customer.id === 'EGGLE')) && (
      <Page size="LETTER" orientation="landscape">
        <View>
          <Text style={style.cncChecklistCustomerText}>{shopOrder.customer.id}</Text>
          <Text style={style.cncChecklistOrderNumberText}>{`${shopOrder.salesOrder}  ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
        </View>
        <Image style={style.checklistPDF} src="/documents/cnc_only_body_checklist.png" />
      </Page>
      )}

      {shopOrder.type === 'neck' && (
        <>
          <Page size="LETTER">
            <View>
              <Text style={style.checklistCompany}>{shopOrder.customer.shortName}</Text>
              <Text style={style.runnerIndex}>{`${shopOrder.salesOrder}  ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
            </View>
            <Image style={style.checklistPDF} src="/documents/neck_milling_cnc_signoff.png" />
          </Page>
          <Page size="LETTER" orientation="landscape">
            <View>
              <Text style={style.neckMillingQAChecklistCompany}>{shopOrder.customer.shortName}</Text>
              <Text style={style.neckMillingQARunnerIndex}>{`${shopOrder.salesOrder}  ${runnerIndex + 1}/${shopOrder.runners.length}`}</Text>
            </View>
            <Image style={style.checklistPDF} src="/documents/neck_milling_qa_checklist.png" />
          </Page>
        </>
      )}
    </>
  );
};

export default WorkOrderSupportDocsPage;
