import firebase from 'firebase';
import {
  cloneDeep, find, findIndex, flatten, includes, sample,
} from 'lodash';
import {
  IBomItem, IInventoryChild, IInventoryPart, IOrderItem,
} from 'shared/types/dbRecords';
import { devLog } from 'shared/util/logging';
import {
  IClientRunner, IRunner, IShopOrder, IStepRunner, IWorkOrder,
} from 'pages/Orders/types';
import QBOItem from 'shared/data/QBO/item';
import QBOInventoryAdjustment from 'shared/data/QBO/inventoryAdjustment';
import { RUNNER_BODY_INITIAL_STEP, RUNNER_NECK_INITIAL_STEP } from '../data';
import { IQBOItem, IQuantityAdjustmentItem } from '../types/qbo';
import { reorderPointNotification } from '../messaging';

export const readyParts = (clientRunners: IRunner[]) => clientRunners
  .filter((r: IRunner) => r.step.match(/(ready|release)/i))
  // @ts-ignore
  .map((r: IRunner) => r.partCounts
    .map((partCount: { type: string, count: number}) => partCount.count)
    .reduce((a: number, b: number) => a + b, 0))
  .reduce((a: number, b: number) => a + b, 0);

export const productionParts = (clientRunners: IRunner[]) => clientRunners
  .filter((r: IRunner) => !r.step.match(/(ready|hold|complete)/i))
  // @ts-ignore
  .map((r: IRunner) => r.partCounts
    .map((partCount: { type: string, count: number}) => partCount.count)
    .reduce((a: number, b: number) => a + b, 0))
  .reduce((a: number, b: number) => a + b, 0);

export const runnerValue = (runners: IStepRunner[]) => flatten(runners
  .map((_stepRunners: IStepRunner) => _stepRunners.runners))
  .filter((runner: IClientRunner) => !runner.step.match(/(complete|ready|hold)/i))
  .map((runner: IRunner) => runner.value || 0)
  .reduce((a: number, b: number) => a + b, 0);

export const stepRunnerData = (item: IStepRunner) => {
  const partCount = item.runners.map((r: IRunner) => r.parts.filter((p: IOrderItem) => p.Sku.match(/[A-Z]{5}/))
    .map((p: IOrderItem) => p.quantityAssigned)
    .reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);
  const value = item.runners.map((r: IRunner) => r.parts.map((p: IOrderItem) => p.quantityAssigned * p.unitPrice)
    .reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);

  // wip eligible bom is only worth as much as the materials that have been consumed.
  // if inventory is still in inventory, it's not part of WIP.
  const wipEligibleBom = item.runners.map((r: IRunner) => r.parts.map((p: IOrderItem) => {
    const bomTotal = p.bom.filter((b: IBomItem) => !!b.quantityConsumed).map((b: IBomItem) => b.totalCost).reduce((a, b) => a + b, 0);
    return bomTotal * p.quantityAssigned;
  }).reduce((a, b) => a + b, 0))
    .reduce((a, b) => a + b, 0);

  const wipValue = item.wipCalc >= 1 ? wipEligibleBom + (partCount * item.wipCalc) : value * item.wipCalc;

  return { partCount, value, wipValue };
};
export const generateRunners = (currentShopOrder: IShopOrder, orderItems: IOrderItem[]) => {
  const workOrders = currentShopOrder.runners || [];
  if (workOrders.length === 0) return [];
  const orderUnitValue = currentShopOrder.orderValue / currentShopOrder.partCount;
  const runnerInitialStep = currentShopOrder.type.match(/body/i) ? RUNNER_BODY_INITIAL_STEP : RUNNER_NECK_INITIAL_STEP;
  const dateCreated = firebase.firestore.Timestamp.fromDate(new Date());
  return workOrders.map((workOrder, index) => {
    const updatedParts = workOrder.parts
      .filter((p: IOrderItem) => p.quantityAssigned > 0)
      .map((p: IOrderItem) => {
        const orderItem = find(orderItems, (i) => i.id === p.id);
        if (!orderItem) return p;
        return { ...p, bom: orderItem.bom };
      });
    // only count actual parts, not service charges
    const partCount = workOrder.parts.filter((p) => p.Sku.match(/^[A-Z]{5}/)).map((p) => p.quantityAssigned).reduce((a, b) => a + b, 0);
    const completed = workOrder.parts.filter((p) => p.quantityOpen > 0).length === 0;
    const runner = {
      id: workOrder.id,
      history: workOrder.history || [{
        dateEntered: dateCreated,
        step: runnerInitialStep,
      }],
      runnerNumber: index,
      step: workOrder.step || runnerInitialStep,
      completed,
      toppedOrBound: workOrder.toppedOrBound || false,
      houseSample: workOrder.houseSample || false,
      partCount: partCount || 0,
      parts: updatedParts || [],
      value: workOrder.value || Math.round(partCount * orderUnitValue),
      stepNotes: workOrder.stepNotes || {},
      description: workOrder.description || null,
      // timeStudy: workOrder.timeStudy || false,
    };

    return runner;
  });
};

export const stepForPart = (partNumber: string, partType: 'body'|'neck'): string => {
  const steps = {
    body: {
      // body steps
      100: 'd9RR4FS3O',
      110: 't0FS3ARf0',
      120: 't0FS3ARf0',
      121: 't0FS3ARf0',
      122: 't0FS3ARf0',
      123: 'DsGT5gQg-dn',
    },
    neck: {
      // neck steps
      140: 'w6onwpgNRxd',
      110: 'Q9ANbIKcC',
      150: 'Q9ANbIKcC',
      151: 'Q9ANbIKcC',
      152: 'Q9ANbIKcC',
      122: '8itJFuQJVT0',
      153: '8itJFuQJVT0',
      154: '8itJFuQJVT0',
      155: 'ejhdPLv2l',
      156: 'rAkvCIHRH6i',
      157: 'aaKxatWdehe',
      159: '8itJFuQJVT0',
    },
  };
  // @ts-ignore
  return steps[partType][partNumber.slice(0, 3)];
};

const updateDbItems = async (
  firestore: any,
  database: any,
  messageDbString: string,
  reorderItemNotificationUsers: string[],
  inventoryDbString: string,
  updateItems: { qboItem: IQBOItem, helmItem: IInventoryPart }[],
  progressLabelSetter: any,
): Promise<boolean> => {
  try {
    if (progressLabelSetter) progressLabelSetter('Updating Quickbooks...');
    await Promise.all(updateItems.map((u) => QBOItem.update(u.qboItem)));
    if (progressLabelSetter) progressLabelSetter('Updating HELM...');
    await Promise.all(updateItems.map((u) => firestore.collection(inventoryDbString)
      .doc(u.helmItem.Sku)
      .update({ QtyOnHand: u.helmItem.QtyOnHand })
      .then(() => {
        if (u.helmItem.QtyOnHand <= u.helmItem.reorderQty && u.helmItem.reorderQty > 0) {
          // if, in the process of consumption, the reorder point for a part has been triggered,
          // send a message to the reorder point notification group.
          reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, u.helmItem);
        }
      })));
    return true;
  } catch (e) {
    return false;
  }
};

export const consumeMaterial = async (
  consumedParts: { [p: string]: number },
  inventoryItems: IInventoryPart[],
  consumptionDate: Date,
  firestore: any,
  database: any,
  messageDbString: string,
  reorderItemNotificationUsers: string[],
  inventoryDbString: string,
  progressLabelSetter: any,
) => {
  const updateItems = Object.entries(consumedParts).map((entry) => {
    const [partNumber, quantityConsumed] = entry;
    devLog('shared/router/utils', 127, `Looking for part: ${partNumber}`);
    let consumeQuantity = quantityConsumed;
    let helmItem = find(inventoryItems, (p: IInventoryPart) => p.Sku === partNumber) as IInventoryPart;
    if (!helmItem) return;
    if (helmItem.Children) {
      const children = inventoryItems.filter((i: IInventoryPart) => includes(helmItem.Children.map((c: IInventoryChild) => c.sku), i.Sku));
      if (children.length === 0) return;
      if (children.length === 1) helmItem = children[0];
      else {
        helmItem = sample(children) as IInventoryPart;
        consumeQuantity = quantityConsumed / (helmItem.Parent?.ParentPerChild || 1);
      }
    }
    devLog('shared/router/utils', 128, `Consuming ${consumeQuantity} of ${helmItem.Sku} - ${helmItem.Description}`);
    const newQuantityOnHand = Math.max(0, helmItem.QtyOnHand - consumeQuantity);
    devLog('shared/router/utils', 128, `Was ${helmItem.QtyOnHand}, is ${newQuantityOnHand}`);
    const qboItem = QBOItem.fromPart({ ...helmItem, QtyOnHand: newQuantityOnHand }, consumptionDate || new Date());
    return { qboItem, helmItem: { ...helmItem, QtyOnHand: newQuantityOnHand } };
  });

  try {
    await updateDbItems(
      firestore,
      database,
      messageDbString,
      reorderItemNotificationUsers,
      inventoryDbString,
      updateItems as { qboItem: IQBOItem, helmItem: IInventoryPart }[],
      progressLabelSetter,
    );
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const consumeInventoryParts = async (
  consumedParts: { [partNumber: string]: number },
  inventoryItems: IInventoryPart[],
  inventoryDbString: string,
  firestore: any,
  database: any,
  messageDbString: string,
  reorderItemNotificationUsers: string[],
  progressLabelSetter: any,
  consumptionDate: Date = new Date(),
  order: IShopOrder,
): Promise<boolean> => {
  if (progressLabelSetter) progressLabelSetter('Analyzing materials...');
  const consumptionRef = await database.ref('/util/adjustmentData').once('value');
  const consumptionData = consumptionRef.val();
  // if we have a lock on the consumption data, update the consumption data object in the database
  // with the quantity to consume for the part in question.
  const consumedItems = Object.keys(consumedParts).map((k) => {
    let inventoryItem = find(inventoryItems, (i: IInventoryPart) => i.Sku === k) as IInventoryPart;

    // if the item needing to be consumed has children, we need to consume from one of the children
    if (inventoryItem.Children) {
      const children = inventoryItems.filter((i: IInventoryPart) => includes(inventoryItem.Children.map((c: IInventoryChild) => c.sku), i.Sku));
      if (children.length === 1) inventoryItem = children[0];
      else if (children.length > 1) {
        inventoryItem = sample(children) as IInventoryPart;
      }
    }
    const qtyOnHand = inventoryItem?.QtyOnHand || 0;
    const qtyDiff = Math.max(qtyOnHand * -1, consumedParts[k] * -1);
    return { ...inventoryItem, adjustmentQuantity: qtyDiff };
  });
  if (consumedItems.length === 0) return;
  const inventoryAdjustment = await QBOInventoryAdjustment.itemAdjustmentFromParts(
    consumedItems,
    null,
    consumptionDate,
    `${order.customer.DisplayName} - S.O. ${order.salesOrder}`,
  );
  if (consumptionData?.lock) {
    await database.ref(`/util/adjustmentData/${consumptionDate.getTime()}`).set(inventoryAdjustment);
    return true;
  }
  // if there is no consumption lock, make the inventory adjustment to QBO, then iteratively consume material from HELM.
  await QBOInventoryAdjustment.create(inventoryAdjustment);
  await Promise.all(consumedItems.map((c: IQuantityAdjustmentItem) => firestore.collection(inventoryDbString)
    .doc(c.Sku)
    .update({ QtyOnHand: c.QtyOnHand + c.adjustmentQuantity }).then(() => {
      if (c.QtyOnHand <= c.reorderQty && c.reorderQty > 0) {
        reorderPointNotification(database, messageDbString, reorderItemNotificationUsers, c);
      }
    })));
};
