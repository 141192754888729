import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { noop } from 'lodash';
import { FlexRow } from 'shared/containers/FlexContainer';
import { currentInventoryPartAtom } from 'shared/state/inventoryState';
import DetailNumberInputWithCallback from 'shared/components/Input/DetailNumberInputWIthCallback';
import DetailInputWithCallback from 'shared/components/Input/DetailInputWIthCallback';
import QBOItem from 'shared/data/QBO/item';
import CostTypeSelector from './InventoryDetailFields/CostTypeSelector';
import { formatPrice } from '../../../shared/data';

const ComponentWrapper = styled(FlexRow)`
  justify-content: flex-start;
  gap: 8px;
`;

interface IComponent {
  disabled: boolean;
  partType: string;
}
const InventoryPartCosting = ({ partType, disabled }: IComponent) => {
  const [partState, setPartState] = useRecoilState(currentInventoryPartAtom);
  const [_disabled, setDisabled] = useState<boolean>(disabled);
  const [_partType, setPartType] = useState<string>(partType);
  const [margin, setMargin] = useState<string>('');

  const onPartSellChange = (value: number) => {
    setPartState({ ...partState, UnitPrice: value });
    const m = ((value - partState.PurchaseCost) / value).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
  };

  const onPurchaseUnitChange = (unit: string) => {
    setPartState({ ...partState, PurchasingUnit: unit });
  };
  const onPartCostChange = (value: number) => {
    setPartState({ ...partState, PurchaseCost: value });
    const m = ((partState.UnitPrice - value) / partState.UnitPrice).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
  };

  useEffect(() => {
    const m = ((partState.UnitPrice - partState.PurchaseCost) / partState.UnitPrice).toFixed(4);
    setMargin(`${(m * 100).toFixed(2)}%`);
  }, [partState]);

  useEffect(() => {
    const inventoryType = QBOItem.partType(partType || '');
    setDisabled(disabled);
    setPartType(inventoryType);
  }, [disabled, partType]);

  return (
    <>
      <ComponentWrapper>
        {/* {_partType !== 'Non-Inventory' && ( */}
        <DetailNumberInputWithCallback
          id="unit-price"
          label="Unit Sell"
          placeholder="$1.00"
          value={formatPrice(partState.UnitPrice)}
          callback={onPartSellChange}
          isCurrency
          disabled={partState.Children?.length}
          // disabled={_partType !== 'Inventory' || partState.Children?.length}
        />
        {/* )} */}
        <DetailNumberInputWithCallback
          id="unit-cost"
          label={`${partState.Children?.length ? 'Avg. Cost' : 'Purchase Price'}`}
          placeholder="$1.00"
          value={formatPrice(partState.PurchaseCost)}
          callback={onPartCostChange}
          isCurrency
          useHelper={partState.Children?.length}
          disabled={_disabled || partState.Children?.length}
          helper="Because this part has sub-items, its average cost is based on the purchase weight of each sub-item."
        />
        <DetailInputWithCallback
          id="purchase-unit"
          label={`${partState.Children?.length ? 'Allocation' : 'Purchase'} Unit`}
          placeholder="EA"
          value={partState.PurchasingUnit}
          callback={onPurchaseUnitChange}
          disabled={_disabled}
        />
        {_partType === 'Inventory' && (
        <DetailInputWithCallback
          disabled
          id="unit-margin"
          label="Margin"
          placeholder="20%"
          value={margin}
          callback={noop}
        />
        )}
        <DetailNumberInputWithCallback
          disabled
          id="unit-last-purchase-cost"
          label="Last Purchase Price"
          placeholder="$1.00"
          value={partState.PurchaseCost}
          callback={noop}
          isCurrency
        />
      </ComponentWrapper>
    </>
  );
};

export default InventoryPartCosting;
