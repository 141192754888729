import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DatePicker, Input, Modal } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import moment from 'moment/moment';
import firebase from 'firebase';
import {
  DatePickerNextIcon,
  DatePickerPrevIcon,
  ItemDetailsColumnWrapper,
  ItemDetailsLabel,
} from 'shared/styledComponents/inputs';
import { invalidOrderDate, isHoliday, isWorkDay } from 'shared/data/calendar';
import { currentShopOrderAtom } from 'shared/state/orderState';
import { salesOrderEditedAtom } from 'shared/state/routingState';
import { FlexRow } from '../../containers/FlexContainer';

const ShipDatePicker = styled(DatePicker)`
  & * {
    color: black !important;
  }
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;
interface IComponent {
  value: Date|null;
  id: string;
  label: string;
  LabelExtra?: any
  callback: (date: Date) => void;
  disabled: boolean;
  disableDates: boolean;
}

const DetailDateWithCallback = ({
  id, label, disabled, value, callback, disableDates = true, LabelExtra = <div />,
}: IComponent) => {
  const [date, setDate] = useState<undefined|Date>(value !== null ? new Date(value) : undefined);
  const onChange = (_date: any) => {
    if (_date === null) return; // this will happen if the "x" button is clicked instead of the date field itself
    const jsDate = new Date(_date.year(), _date.month(), _date.date());
    if (disableDates && (isHoliday(jsDate) || !isWorkDay(jsDate))) {
      return Modal.error({
        content: 'Invalid date. Please choose a weekday that is not a holiday.',
        okText: 'OK',
      });
    }
    setDate(jsDate);
    callback(jsDate);
  };

  useEffect(() => {
    setDate(value !== null ? new Date(value) : undefined);
  }, [value]);

  const onBlur = (e: any) => {
    callback(date);
  };

  return (
    <ItemDetailsColumnWrapper key={`${id}-date-item-details-wrapper`}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-date-item-details-label`}>{label}</ItemDetailsLabel>
        {LabelExtra}
      </LabelWrapper>
      <ShipDatePicker
        key={`${id}-date-picker`}
        id={`${id}-date-picker`}
        disabledDate={disableDates ? invalidOrderDate : null}
        onChange={onChange}
        onBlur={onBlur}
        value={(date && moment(date).isValid()) ? moment(date) : undefined}
        disabled={disabled}
        nextIcon={<DatePickerNextIcon />}
        prevIcon={<DatePickerPrevIcon />}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default DetailDateWithCallback;
