import React from 'react';
import { stageRedirect } from 'shared/util';
import {
  RouterTDButton,
} from 'pages/ProductionSchedule/styledComponents';
import { IShopOrder } from '../../../../../Orders/types';

interface IComponentInterface {
  record: IShopOrder;
}

const ViewOrderButton = ({ record }: IComponentInterface) => {
  console.log(record);
  const onClick = (e: any) => {
    e.preventDefault();
    // sessionStorage.setItem('requirement.drawer.part', )
    stageRedirect();
    window.location.href = `orders/create?edit=true&orderId=${record.id}`;
  };

  return (
    <RouterTDButton onClick={onClick}>{record.salesOrder}</RouterTDButton>
  );
};

export default ViewOrderButton;
