import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { ItemDetailsColumnWrapper, ItemDetailsLabel } from 'shared/styledComponents/inputs';
import { formatPercent, formatPrice } from '../../data';
import { FlexRow } from '../../containers/FlexContainer';
import HelpButton from '../Utility/HelpButton';

// @ts-ignore
const FieldInput = styled(Input)`
  width: ${(props: { width: string }) => props.width || '144px'};
  padding-left: ${(props: { isLabel: boolean }) => (props.isLabel ? '0' : '4px')};
    &:disabled {
        background-color: ${(props: any) => (props.isLabel ? 'transparent' : '#EEEEEE')};
        border: ${(props: any) => (props.isLabel ? 'none' : '1px solid #d9d9d9')};
    }
`;

const LabelWrapper = styled(FlexRow)`
    justify-content: flex-start;
    gap: 4px;
`;

interface IComponent {
  id: string;
  label: string;
  placeholder: string;
  value: number;
  callback: (value: number) => void;
  width?: string;
  disabled?: boolean
  isLabel?: boolean;
  noMargin?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  useHelper?: boolean;
  helper?: string;
  max?: number;
  min?: number;
}
const DetailNumberInputWithCallback = ({
  id, value, callback, label, placeholder, width, disabled = false, isLabel = false, noMargin = false, isCurrency = false, isPercent = false, useHelper = false, helper = undefined, max = undefined, min = undefined,
}: IComponent) => {
  const [fieldValue, setFieldValue] = useState<string|undefined>(value);

  const onChange = (e: any) => {
    setFieldValue(e.target.value);
  };

  const formatValue = (_value: number) => {
    let formatted = _value;
    if (isCurrency) {
      formatted = formatPrice(_value);
    } else if (isPercent) {
      if (_value > 1) {
        formatted = formatPercent(_value, 0, false);
      } else {
        formatted = formatPercent(_value, 0);
      }
    } else {
      const wholeNumber = Math.ceil(_value);
      if (Math.abs(_value - wholeNumber) !== 0) {
        formatted = _value.toFixed(2);
      } else {
        formatted = _value;
      }
    }
    return formatted.toString();
  };
  const onBlur = (e: any) => {
    const numericValue = e.target.value === '' ? 0 : parseFloat(e.target.value.match(/[-0-9\\.]+/)[0]);
    if (max && numericValue > max) setFieldValue(formatValue(max));
    else if (min && numericValue < min) setFieldValue(formatValue(min));
    else setFieldValue(formatValue(numericValue));
    callback(numericValue);
  };

  useEffect(() => {
    setFieldValue(formatValue(value));
  }, [value]);

  return (
    <ItemDetailsColumnWrapper key={`${id}-item-details-wrapper`} noMargin={noMargin}>
      <LabelWrapper>
        <ItemDetailsLabel key={`${id}-item-details-wrapper`}>{label}</ItemDetailsLabel>
        {useHelper && (
          <HelpButton helpMessage={helper} width="20px" style={{ width: '20px', marginBottom: '4px' }} />
        )}
      </LabelWrapper>
      <FieldInput
        width={width}
        key={`${id}-input-field`}
        id={`${id}-input-field`}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={fieldValue}
        disabled={disabled}
        isLabel={isLabel}
      />
    </ItemDetailsColumnWrapper>
  );
};

export default DetailNumberInputWithCallback;
